<template>
  <d-container fluid>
    <pageTitle :title="`Leverancier: ${supplier.name }`" :back="true"/>
    <d-row>
      <vCard :col="8" header="Leverancier adressen" :fluid="true">
        <vTable :fields="fields" :actions="actions" :data="data"/>
      </vCard>
      <vCard :col="4" header="Leverancier adres toevoegen">
        <addAddress :supplier="supplier" @submit="eventAddSupplierAddress" />
      </vCard>
    </d-row>
  </d-container>
</template>

<script>

export default {
  name: 'supplierAddress',
  components: {
    addAddress: () => import('@/components/forms/supplierAddress/addSupplierAddress')
  },
  created() {
    this.getSupplier()
    this.getAddress()
  },
  data() {
    return {
      fields: [
        {
          name: 'city',
          title: 'Stad',
          sortField: 'city'
        },
        {
          name: 'street',
          title: 'Straat',
          sortField: 'street'
        },
        {
          name: 'house_number',
          title: 'Huisnummer',
        },
        {
          name: 'is_delivery_address',
          title: 'word gebracht',
          formatter: (value) => {
            return value ? '<i class="material-icons">check_circle</i>' : '<i class="material-icons">clear</i>'
          }
        },
        'actions'
      ],
      data: null,
      actions: [
        {
          icon: '<i class="material-icons">visibility</i>',
          fn: (address) => {
            this.$router.push({
              name: 'dashboard/inkoop/supplier/address',
              params: {supplier_id: this.$route.params.supplier_id, address_id: address.id}
            })
          }
        }
      ],
      supplier: {
        id: null,
        name: null
      }
    }
  },
  methods: {
    async getSupplier() {
      let response = await this.$api.supplier.get(this.$route.params.supplier_id)
      this.supplier = response.data
    },
    async getAddress() {
      let response = await this.$api.supplierAddress.getBySupplier(this.$route.params.supplier_id)
      this.data = response.data
    },
    eventAddSupplierAddress(value) {
      this.data.push(value)
    }
  }
}
</script>
